<!--
 * @Description: 企业找回账号-企业审核第二步
 * @Version:
 * @Autor: houchuanfang
 * @Date: 2022-03-16 14:18:32
 * @LastEditTime: 2022-03-18 10:29:09
-->
<template>
    <div class="retrieve">
        <div class="content">
            <div class="title">
                <h3>兰州市残疾人就业创业网络服务平台 | 信息审核</h3>
                <router-link to="/">返回首页</router-link>
            </div>
            <div class="tab">
                <h1>通过以下流程找回账号密码</h1>
                <ul class="ul">
                    <li>
                        <img src="~/static/login/01_active.png" alt="">
                        <h3>提供资料</h3>
                    </li>
                    <li class="active">
                        <img src="~/static/login/02_active.png" alt="">
                        <h3>信息审核</h3>
                    </li>
                    <li>
                        <img src="~/static/login/03.png" alt="">
                        <h3>找回账号密码</h3>
                    </li>
                </ul>
            </div>
            <div class="form">
                <img src="@/static/public/tip.png" alt="">
                <h5>信息审核中</h5>
                <p class="text-tips">预计1-3个工作日完成审核，结果将以短信活电话方式通知您，请耐心等待~</p>
                <div class="btn">
                    <el-button type="primary" class="w336" @click="goRouter">上一步</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'index',
    data() {
        return {
            checkbox: '1',
            url: '/retrievePassword/phone',
        };
    },
    watch: {},
    created() {},
    methods: {
        handChange(val) {
            if (val == 1) {
                this.url = '/retrievePassword/phone';
            } else if (val == 2) {
                this.url = '/retrievePassword/email';
            } else {
                this.url = '/retrievePassword/info';
            }
        },
        goRouter() {
            this.$router.push('/');
        },
    },
};
</script>

<style scoped lang="less">
.retrieve {
    width: 100%;
    min-height: calc(100vh - 269px);
    background: white;

    .w336 {
        width: 336px;
    }

    .content {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 100px;
        .title {
            display: flex;
            padding-top: 26px;
            h3 {
                flex: 1;
                font-size: 18px;
                color: #28a46d;
            }
            a {
                font-size: 18px;
                color: #66716c;
            }
            a:hover {
                color: #28a46d;
            }
        }
        .tab {
            width: 635px;
            margin: 0 auto;
            padding-top: 60px;
            h1 {
                font-size: 28px;
                padding-bottom: 36px;
                text-align: center;
            }
            .ul {
                display: flex;
                li {
                    position: relative;
                    flex: 1;
                    text-align: center;
                    h3 {
                        font-size: 18px;
                        margin-top: 6px;
                        color: #66716c;
                    }
                }
                li.active h3 {
                    color: #28a46d;
                }
                li::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -50px;
                    transform: translateY(-50%);
                    width: 102px;
                    height: 2px;
                    background: #ccd0ce;
                }
                li:last-child::after {
                    display: none;
                }
            }
        }
    }
    .tips {
        color: #000;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        margin: 30px 0;
    }
    .form {
        width: 510px;
        margin: 50px auto 0;
        img {
            display: block;
            width: 40px;
            height: 40px;
            background: #ececec;
            border-radius: 50%;
            margin: 0 auto;
        }
        h5 {
            text-align: center;
            font-size: 18px;
            margin: 20px;
        }
        .text-tips {
            width: 70%;
            font-size: 14px;
            color: #66716c;
            margin: 0 auto;
            margin-bottom: 20px;
            text-align: center;
        }
        .btn {
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
</style>
